@import "./colours";


.action-button:hover{
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.action-button{
  transition: all 0.2s, background-color 0.3s, border 0.1s;

  position: relative;
  //height: 50px;
  //width: 250px;
  height: 40.5px;
  width: max-content;
  outline: none;
  padding: 8px 10px;
  margin-bottom: 10px;

  border-radius: 15px;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;

  font-size: 20px;
  font-weight: bold;
  font-family: Montserrat;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0px solid rgba(0, 0, 0, 0.4);

  background-color: #fce890;

  &.active{
    background-color: #ffdd1f;
  }

  &.passive{
    background-color: #d9d3b6;
    height: 0px !important;
    padding: 0;
    overflow: hidden;
    margin-bottom: 0;
  }

  &.stretch{
    width: 100%;
  }

  &.secondary{
    background: white;
    border: 2px solid #b6a216;
    color: #b6a216;

    &:hover{
      background: #ffdd1f;
      color: white;
    }
  }
}
