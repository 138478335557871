.secondary-button{
  background: none;
  outline: none;

  font-size: 18px;
  color: #574e2b;
}

.secondary-button:hover{
  text-decoration: underline;
}