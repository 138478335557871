@import "./styles/actionButton";
@import "./styles/colours";
@import "./styles/secondary-button";
@import "./styles/text";

@import url(https://fonts.googleapis.com/css?family=Montserrat:425,500,700,900&display=swap);

div{
  font-family: Montserrat, sans-serif;
}

button{
  font-family: Montserrat, sans-serif;
  background: none;
  border: none;
}
