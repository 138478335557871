.description{
  border-radius: 15px;

  width: 50%;
  padding: 10px;
  text-align: center;

  margin-bottom: 20px;
  margin-top: 20px;
}

.H1{
  font-size: 30px;
  color: rgba(0, 0, 0, 0.75);
}